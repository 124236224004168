import {
	Descriptions,
	Table,
	DatePicker,
	Radio,
	Pagination,
} from "antd";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import Moment from "moment";
import "moment/locale/tr";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getSessions } from "../../../../core/apis/Apis";
import {
	setError,
	setLoading,
	setSuccess,
} from "../../../../core/stores/apiStatusControllerSlice";
import "./Sessions.scss";
import gen2Devices from "../../../../../src/gen2_devices.json";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { SEO_HELMET } from "../../../../core/utils/helmet";
import { setTitle } from "../../../../core/stores/navbarTitleControllerSlice";
import { useTranslation } from "react-i18next";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = "DD-MM-YYYY";

function Sessions() {
	/* -------------------------------------------------------------------------- */
	//TODO -                             SELECTORS                                */
	/* -------------------------------------------------------------------------- */
	const dispatch = useDispatch();
	const { t } = useTranslation();

	/* -------------------------------------------------------------------------- */
	//TODO -                             MEDIAQUERY                                */
	/* -------------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------- */
	//TODO -                             STATES                                   */
	/* -------------------------------------------------------------------------- */
	const [record] = useState({
		gtwID: window.location.pathname.split("GTWY-")[1].split("/")[0],
	});
	const [filteredInfo, setFilteredInfo] = useState({});
	const [responseData, setResponseData] = useState();
	const [responseDataLoading, setResponseDataLoading] = useState(false);
	const [dateString, setDateString] = useState([
		dayjs().format(dateFormat),
		dayjs().format(dateFormat),
	]);
	const [logType, setLogType] = useState("1");
	const [tableColumns, setTableColumns] = useState([]);
	const [filterCounter, setFilterCounter] = useState(0);
	const [sessionsStatus, setSessionsStatus] = useState([]);
	const [sessionsMessage, setSessionsMessage] = useState([]);
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 500,
		total: 0,
		showSizeChanger: true,
		pageSizeOptions: ["100", "250", "500", "1000"],
	});
	/* -------------------------------------------------------------------------- */
	//TODO                                Functions                               */
	/* -------------------------------------------------------------------------- */
	useEffect(() => {
		dispatch(setTitle(t("pages.sessions.title")));
		setResponseDataLoading(true);
		if (dateString.length > 0) {
			getDataFilterDate(1, 500);
		} else {
			getData(1, 500);
		}
		return () => {
			setResponseDataLoading(false);
			setResponseData(null);
			setFilterCounter(0);
			setPagination((prev) => ({ ...prev, current: 1, total: 0 }));
		};
	}, []);

	const devices = gen2Devices["devices"].map(
		(deviceID) => deviceID.toString().split("-")[1]
	);

	const getData = (page = 1, pageSize = 10) => {
		const params = {
			page: page,
			page_size: pageSize,
		};

		getSessions("GTWY-" + record.gtwID, params)
			.then((res) => {
				if (res.status === 200) {
					setResponseData(res.data.results);
					setPagination((prev) => ({
						...prev,
						current: page,
						pageSize: pageSize,
						total: res.data.count,
					}));
				} else {
					toast.error(`${res.data.error}`, {
						position: "top-right",
						autoClose: false,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}
				setResponseDataLoading(false);
				dispatch(setError(false));
				dispatch(setSuccess(true));
				dispatch(setLoading(false));
			})
			.catch((err) => {
				setResponseDataLoading(false);
				dispatch(setError(true));
				dispatch(setSuccess(false));
				dispatch(setLoading(false));
				toast.error(t("toast.error_text"), {
					position: "top-right",
					autoClose: false,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				toast.error(`${err}`, {
					position: "top-right",
					autoClose: false,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			});
	};

	const getDataFilterDate = (page = 1, pageSize = 10) => {
		setResponseDataLoading(true);
		const params = {
			start: dateString[0],
			end: dateString[1],
			log_type: logType,
			page: page,
			page_size: pageSize,
		};

		getSessions("GTWY-" + record.gtwID, params)
			.then((res) => {
				if (res.status === 200) {
					setResponseData(res.data.results);
					setPagination((prev) => ({
						...prev,
						current: page,
						pageSize: pageSize,
						total: res.data.count,
					}));
				}
				setResponseDataLoading(false);
				dispatch(setError(false));
				dispatch(setSuccess(true));
				dispatch(setLoading(false));

				setFilterCounter((prev) => prev + 1);
			})
			.catch((err) => {
				setResponseDataLoading(false);
				dispatch(setError(true));
				dispatch(setSuccess(false));
				dispatch(setLoading(false));
				console.log("err : ", err);
				toast.error(t("toast.error_text"), {
					position: "top-right",
					autoClose: false,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				toast.error(`${err}`, {
					position: "top-right",
					autoClose: false,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			});
	};

	/* -------------------------------------------------------------------------- */
	//TODO                          FOR TABLE PROPS                               */
	/* -------------------------------------------------------------------------- */

	const handleTableChange = (pagination, filters, sorter) => {
		setFilteredInfo(filters);
		setResponseDataLoading(true);

		if (dateString.length > 0) {
			getDataFilterDate(pagination.current, pagination.pageSize);
		} else {
			getData(pagination.current, pagination.pageSize);
		}
	};
	const columns = [
		{
			title: t("pages.sessions.date"),
			dataIndex: "date",
			key: "date",
			width: 140,
			fixed: "left",
		},
		{
			title: t("pages.sessions.status"),
			dataIndex: "status",
			key: "status",
			filteredValue: filteredInfo.status || null,
			filters:
				sessionsStatus.length > 0 &&
				sessionsStatus.map((res) => {
					return {
						text: res.toUpperCase(),
						value: res,
					};
				}),
			onFilter: (value, record) => record.status === value,
			render: (status) => (
				<div style={{ textAlign: "center" }}>{status}</div>
			),
		},
		{
			title: t("pages.sessions.message"),
			dataIndex: "message",
			key: "message",
			filteredValue: filteredInfo.message || null,
			filters:
				sessionsMessage.length > 0 &&
				sessionsMessage.map((res) => {
					return {
						text: res.toUpperCase(),
						value: res,
					};
				}),
			onFilter: (value, record) => record.message === value,
			render: (message) => (
				<div style={{ textAlign: "center" }}>{message}</div>
			),
		},
	];

	const dataSource = useMemo(() => {
		var list = [];
		var sessionStatusList = [];
		var sessionMessageList = [];
		if (responseData) {
			responseData.logs.map((res, index) => {
				if (res.status) {
					sessionStatusList.push(res.status);
				}
				if (res.message) {
					sessionMessageList.push(res.message);
				}

				if (devices.includes(record.gtwID)) {
					list.push({
						key: index,
						date: Moment.unix(res.timestamp).format(
							"DD/MM/YY, HH:mm:ss"
						),
						...res,
					});
				} else {
					list.push({
						key: index,
						date: Moment.unix(res.timestamp).format(
							"DD/MM/YY, HH:mm:ss"
						),
						status: res.status,
						message: res.message,
					});

					setSessionsStatus(Array.from(new Set(sessionStatusList)));
					setSessionsMessage(Array.from(new Set(sessionMessageList)));
				}
			});
		}
		console.log("list : ", list);

		return list;
	}, [responseData]);

	useEffect(() => {
		const generalTableColumns = [
			{
				title: t("pages.sessions.date"),
				dataIndex: "date",
				key: "date",
				width: 140,
				fixed: "left",
			},
			{
				title: "TS",
				dataIndex: "timestamp",
				key: "timestamp",
				width: 120,
				fixed: "left",
			},
			{
				title: t("tables.node_logs_table.compare_timestamp"),
				dataIndex: "ts_diff",
				key: "ts_diff",
				width: 100
			},
		];

		if (devices.includes(record.gtwID)) {
			if (logType === "0") {
				setTableColumns(
					generalTableColumns.concat([
						{
							title: t("pages.sessions.message"),
							dataIndex: "message",
							key: "message",
							render: (text) =>
								text &&
								t(`pages.sessions.system_message_${text}`),
						},
						{
							title: t("pages.sessions.info"),
							dataIndex: "info",
							key: "info",
						},
					])
				);
			} else if (logType === "1") {
				setTableColumns(
					generalTableColumns.concat([
						{
							title: t(
								"pages.gateway_detail.rooms_and_sensors_table.room_id"
							),
							dataIndex: "room_id",
							key: "room_id",
						},
						{
							title: t("pages.sessions.room_type"),
							dataIndex: "room_type",
							key: "room_type",
							render: (text) => <>
								{text} - {t(`room_types.${text}`)}
							</>
						},
						{
							title: t("pages.sessions.person_status"),
							dataIndex: "person_status",
							key: "person_status",
							render: (text) =>
								text && text === "8" ? <b>{t(`pages.sessions.person_status_${text}`)}</b> :
								t(`pages.sessions.person_status_${text}`),
						},
						{
							title: t("pages.sessions.total_motion_trigger"),
							dataIndex: "total_motion_trigger",
							key: "total_motion_trigger",
						},
						{
							dataIndex: "day_of_the_year",
							key: "day_of_the_year",
							title: t("pages.sessions.day_of_the_year"),
						},
					])
				);
			} else if (logType === "2") {
				setTableColumns(
					generalTableColumns.concat([
						{
							title: t("pages.sessions.info"),
							dataIndex: "session_info",
							key: "session_info",
							render: (text) =>
								text &&
								t(`pages.sessions.session_analysis_${text}`),
						},
						{
							title: t("pages.sessions.duration"),
							dataIndex: "duration",
							key: "duration",
      						sortDirections: ["descend", "ascend"],
							sorter: (a, b) => a.duration - b.duration,
						},
						{
							title: t(
								"pages.gateway_detail.description_items.last_room_id"
							),
							dataIndex: "last_room_id",
							key: "last_room_id",
						},
					])
				);
			} else if (logType === "3") {
				setTableColumns(
					generalTableColumns.concat([
						{
							title: t("pages.sessions.message"),
							dataIndex: "sensor_message",
							key: "sensor_message",
						},
						{
							title: t("pages.sessions.info"),
							dataIndex: "sensor_data",
							key: "sensor_data",
						},
					])
				);
			} else {
				setTableColumns(
					generalTableColumns.concat([
						{
							title: t("pages.sessions.message"),
							dataIndex: "message",
							key: "message",
							render: (text) =>
								text &&
								t(`pages.sessions.system_message_${text}`),
						},
						{
							title: t("pages.sessions.info"),
							dataIndex: "info",
							key: "info",
						},
						{
							title: t(
								"pages.gateway_detail.rooms_and_sensors_table.room_id"
							),
							dataIndex: "room_id",
							key: "room_id",
						},
						{
							title: t("pages.sessions.room_type"),
							dataIndex: "room_type",
							key: "room_type",
						},
						{
							title: t("pages.sessions.person_status"),
							dataIndex: "person_status",
							key: "person_status",
							render: (text) =>
								text &&
								t(`pages.sessions.person_status_${text}`),
						},
						{
							title: t("pages.sessions.total_motion_trigger"),
							dataIndex: "total_motion_trigger",
							key: "total_motion_trigger",
						},
						{
							title: t("pages.sessions.info"),
							dataIndex: "session_info" || "info",
							key: "session_info" || "info",
							render: (text) =>
								text &&
								t(`pages.sessions.session_analysis_${text}`),
						},
						{
							title: t("pages.sessions.duration"),
							dataIndex: "duration",
							key: "duration",
						},
						{
							title: t(
								"pages.gateway_detail.description_items.last_room_id"
							),
							dataIndex: "last_room_id",
							key: "last_room_id",
						},
						{
							title: t("pages.sessions.message"),
							dataIndex: "sensor_message",
							key: "sensor_message",
						},
						{
							title: t("pages.sessions.info"),
							dataIndex: "sensor_data",
							key: "sensor_data",
						},
						{
							dataIndex: "day_of_the_year",
							key: "day_of_the_year",
							title: t("pages.sessions.day_of_the_year"),
						},
					])
				);
			}
		} else {
			setTableColumns(columns);
		}

		return () => {
			setTableColumns([]);
		};
	}, [filterCounter]);

	var onChangeRangePicker = (date, newDateString) => {
		setDateString(prev => {
			if (prev[0] === newDateString[0] && prev[1] === newDateString[1]) {
				return prev;
			}
			return newDateString;
		});
	};

	useEffect(() => {
		if (dateString.length > 0) {
			getDataFilterDate(1, 500);
		}
	}, [dateString, logType]);

	return (
		<>
			<SEO_HELMET
				title={`${t("pages.sessions.title")} / ${record.gtwID}`}
			/>
			<div id="Sessions">
				<div className="Sessions-cover">
					{/* <Divider orientation='left'>{record.gtwID} - Sessions</Divider> */}
					<Descriptions
						size="small"
						bordered
						column={{
							xxl: 4,
							xl: 4,
							lg: 4,
							md: 3,
							sm: 2,
							xs: 1,
						}}
					>
						<Descriptions.Item
							label={t("description_items.gateway_id")}
							labelStyle={{ width: "100px" }}
							contentStyle={{ width: "160px" }}
						>
							{record.gtwID}
						</Descriptions.Item>

						{devices.includes(record.gtwID) && (
							<Descriptions.Item
								label={t("pages.sessions.log_type")}
								labelStyle={{ width: "100px" }}
							>
								<Radio.Group
									optionType="button"
									buttonStyle="solid"
									onChange={(e) => setLogType(e.target.value)}
									value={logType}
								>
									<Radio value={null}>{t("pages.sessions.all_logs")}</Radio>
									<Radio value="0">{t("pages.sessions.system_logs")}</Radio>
									<Radio value="1">{t("pages.sessions.person_status_logs")}</Radio>
									<Radio value="2">{t("pages.sessions.session_analysis")}</Radio>
									<Radio value="3">{t("pages.sessions.sensor_data_logs")}</Radio>
								</Radio.Group>
							</Descriptions.Item>
						)}
						<Descriptions.Item
							label={t("description_items.date")}
							labelStyle={{ width: "100px" }}
							contentStyle={{ width: "300px" }}
						>
							<RangePicker
								format={dateFormat}
								value={[
									dayjs(dateString[0], dateFormat),
									dayjs(dateString[1], dateFormat),
								]}
								onChange={(date, dateString) =>
									onChangeRangePicker(date, dateString)
								}
							/>
						</Descriptions.Item>
						<Descriptions.Item
							label={t("tables.total_data")}
							labelStyle={{ width: "100px" }}
							contentStyle={{ width: "160px" }}
						>
							<b>{pagination.total}</b>
						</Descriptions.Item>
					</Descriptions>
					<div className="Sessions-bottom_more_info mt-2">
						<Table
							footer={(currentPageData) => (
								<div className="d-flex align-items-center justify-content-between">
									<div className="d-flex align-items-center gap-3">
										{/* <div>{t('tables.total_data')}: <b>{currentPageData.length}</b></div>
                                        <Button onClick={() => setFilteredInfo({})}>{t('tables.clear_filters')}</Button> */}
									</div>
									<div>
										{pagination && (
											<Pagination
												current={pagination.current}
												pageSize={pagination.pageSize}
												total={pagination.total}
												showSizeChanger={
													pagination.showSizeChanger
												}
												pageSizeOptions={
													pagination.pageSizeOptions
												}
												onChange={(page, pageSize) =>
													handleTableChange(
														{
															current: page,
															pageSize: pageSize,
														},
														filteredInfo,
														null
													)
												}
											/>
										)}
									</div>
								</div>
							)}
							size="middle"
							bordered={true}
							columns={tableColumns}
							dataSource={dataSource}
							pagination={false}
							loading={responseDataLoading}
							scroll={{
								y: "calc(100vh - 320px)",
							}}
							onChange={handleTableChange}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
export default Sessions;
