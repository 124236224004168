import React, { useCallback, useEffect, useState } from 'react'
import { useMemo } from 'react';
import Moment from 'moment';
import 'moment/locale/tr'
import './SupportTickets.scss'
import './../../../components/optim_table/OptimTable.scss'
import Modal from 'react-bootstrap/Modal';

import { useDispatch, useSelector } from 'react-redux';
import { setIsModalOpenAntd, setModalCaptionAntd, setModalDataAntd, setStyleAntd } from '../../../core/stores/antdModalControllerSlice';
import BeatLoader from 'react-spinners/BeatLoader';
import { useRef } from 'react';
import { memo } from 'react';
import { getAllSupportTicketData, getSingleAllSupportTicketData, GET_MainPageTable, SetLocalStorageApi } from '../../../core/apis/Apis';

import { SearchOutlined } from '@ant-design/icons';
import { Badge, Button, Input, Space, Table, Tag, Empty, Image, Row, Typography } from 'antd';
import Highlighter from 'react-highlight-words';
import { setError, setLoading, setSuccess } from '../../../core/stores/apiStatusControllerSlice';
import exportedObject from '../../../core/utils/utils';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import SingleGtwDetail from '../containers/SingleGtwDetail';
import { routes } from '../../../core/route/router';
import { SEO_HELMET } from '../../../core/utils/helmet';
import { setTitle } from '../../../core/stores/navbarTitleControllerSlice';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



function SupportTickets() {

    /* -------------------------------------------------------------------------- */
    //TODO -                             SELECTORS                                */
    /* -------------------------------------------------------------------------- */
    const isModalOpen = useSelector(state => state.modalController.isModalOpen)
    const apiStatusController = useSelector(state => state.apiStatusController)
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();

    /* -------------------------------------------------------------------------- */
    //TODO -                             MEDIAQUERY                                */
    /* -------------------------------------------------------------------------- */
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })


    /* -------------------------------------------------------------------------- */
    //TODO -                             STATES                                   */
    /* -------------------------------------------------------------------------- */

    const [filteredInfo, setFilteredInfo] = useState({});
    const [responseData, setResponseData] = useState();
    const [responseDataLoading, setResponseDataLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const {
        authReducers: { user_info }
    } = useSelector((state) => state)

    /* -------------------------------------------------------------------------- */
    //TODO                                Functions                               */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(setTitle(t('tables.support_tickets_table.title')))
        // SetLocalStorageApi()
        getData()
    }, [])

    const getData = () => {
        getSingleAllSupportTicketData()
            .then(res => {
                if (res.status === 200) {
                    setResponseData(res.data)
                }
                setResponseDataLoading(false)
                dispatch(setError(false))
                dispatch(setSuccess(true))
                dispatch(setLoading(false))
            })
            .catch(err => {
                dispatch(setError(true))
                dispatch(setSuccess(false))
                dispatch(setLoading(false))
                console.log("err : ", err)
                toast.error(t('toast.error_text'), {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                toast.error(`${err}`, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }


    /* -------------------------------------------------------------------------- */
    //TODO                          FOR TABLE PROPS                               */
    /* -------------------------------------------------------------------------- */

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={t('tables.search')}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {t('tables.search')}
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {t('tables.clear')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const getColumnSearchPropsForGtwID = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={t('tables.search')}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {t('tables.search')}
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {t('tables.clear')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, record) => {
            if (user_info?.permissions?.support_tickets_detail_page_read) {
                if (searchedColumn === dataIndex) {
                    return <Typography.Link
                        href={`${process.env.REACT_APP_MEDIA_URL + routes.single_support_ticket.path}/GTWY-${record.gtwID}/ticket/${record.spid}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Highlighter
                            highlightStyle={{
                                backgroundColor: '#ffc069',
                                padding: 0,
                            }}
                            searchWords={[searchText]}
                            autoEscape
                            textToHighlight={text ? text.toString() : ''}
                        />
                    </Typography.Link>
                }
                else {
                    return <Typography.Link
                        href={`${process.env.REACT_APP_MEDIA_URL + routes.single_support_ticket.path}/GTWY-${record.gtwID}/ticket/${record.spid}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {text}
                    </Typography.Link>
                }
            } else {
                return <div  style={{ textAlign: 'center'}}>
                    <Typography.Link
                        href={"##"}
                        onClick={() => {
                            toast.error(t('toast.permission_error'), {
                                position: "top-right",
                                autoClose: false,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }}
                    >
                    {text}
                    </Typography.Link>
                </div>
            }
        }
    });


    const tablePropOnChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
    };


    const dataSource = useMemo(() => {
        var list = []
        if (responseData) {
            [...responseData].sort((a,b) => b.ts - a.ts).map((res, index) => {
                if (res.status !== 'resolved') {
                    list.push({
                        key: index,
                        spid: res.id,
                        gtwID: res.device_serial ? res.device_serial.split('GTWY-')[1] : 'None',
                        date: Moment.unix(res.ts).format("DD/MM/YY, HH:mm"),
                        status: res.status,
                        sp: res.level,
                        customer_id: res.customer_id,
                        contact_info: res?.contact_phone || res?.contact_mail,
                        app_version: res.app_version,
                        detail: res.first_message,
                        tenant_id: res.tenant_id
                    })
                }
            })
        }
        return list
    }, [responseData])

    const columns = [
		{
			title: t("tables.gtw_id"),
			dataIndex: "gtwID",
			key: "gtwID",
			width: 125,
			fixed: "left",
			sorter: (a, b) => a.gtwID?.length - b.gtwID?.length,
			filteredValue: filteredInfo.gtwID || null,
			...getColumnSearchPropsForGtwID("gtwID"),
		},
		{
			title: t("tables.date"),
			dataIndex: "date",
			key: "date",
			width: 140,
			filteredValue: filteredInfo.date || null,
		},
		{
			title: t("tables.customer_id"),
			dataIndex: "customer_id",
			key: "customer_id",
			width: 160,
			filteredValue: filteredInfo.customer_id || null,
			sorter: (a, b) => a.customer_id - b.customer_id,
			...getColumnSearchProps("customer_id"),
		},
		{
			title: t("tables.tenant_id"),
			dataIndex: "tenant_id",
			key: "tenant_id",
			filters: [
				...new Set(dataSource?.map((item) => item.tenant_id)),
			].map((item) => ({ text: item, value: item })),
			filterSearch: true,
			filteredValue: filteredInfo.tenant_id || null,
			onFilter: (value, record) => record.tenant_id.indexOf(value) === 0,
			width: 100,
		},
		{
			title: t("tables.support_tickets_table.ticket_id"),
			dataIndex: "spid",
			key: "spid",
			width: 160,
			filteredValue: filteredInfo.spid || null,
			sorter: (a, b) => a.spid - b.spid,
			filters: [
				...new Set(responseData?.map((res, index) => res.id)),
			].map((res, index) => {
				return { text: res, value: res };
			}),
			onFilter: (value, record) => record.spid === value,
			filterSearch: true,
			render: (spid) => <div style={{ textAlign: "center" }}>{spid}</div>,
		},
		{
			title: t("tables.status"),
			dataIndex: "status",
			key: "status",
			width: 160,
			sortDirections: ["descend", "ascend"],
			filteredValue: filteredInfo.status || null,
			filters: [
				{
					text: (
						<Tag style={{ margin: "0" }} color={"orange"}>
							{t("tables.support_tickets_table.filters.new")}
						</Tag>
					),
					value: "new",
				},
				{
					text: (
						<Tag style={{ margin: "0" }} color={"green"}>
							{t("tables.support_tickets_table.filters.work_in_proggress")}
						</Tag>
					),
					value: "work_in_proggress",
				},
				{
					text: (
						<Tag style={{ margin: "0" }} color={"red"}>
							{t("tables.support_tickets_table.filters.unresolved")}
						</Tag>
					),
					value: "unresolved",
				},
			],
			onFilter: (value, record) => record.status === value,
			sorter: (a, b) => a.status.length - b.status.length,
			render: (status) => (
				<div style={{ textAlign: "center" }}>
					{status == "new" ? (
						<Tag style={{ margin: "0" }} color={"orange"}>
							{t("tables.support_tickets_table.filters.new")}
						</Tag>
					) : status == "work_in_proggress" ? (
						<Tag style={{ margin: "0" }} color={"green"}>
							{t("tables.support_tickets_table.filters.work_in_proggress")}
						</Tag>
					) : status == "unresolved" ? (
						<Tag style={{ margin: "0" }} color={"red"}>
							{t("tables.support_tickets_table.filters.unresolved")}
						</Tag>
					) : (
						<Tag style={{ margin: "0" }} color={"orange"}>
							{t("tables.support_tickets_table.filters.new")}
						</Tag>
					)}
				</div>
			),
		},
		{
			title: t("tables.support_tickets_table.support_level"),
			dataIndex: "sp",
			key: "sp",
			width: 160,
			sortDirections: ["descend", "ascend"],
			filteredValue: filteredInfo.sp || null,
			filters: [
				{
					text: 1,
					value: 1,
				},
				{
					text: 2,
					value: 2,
				},
				{
					text: 3,
					value: 3,
				},
			],
			onFilter: (value, record) => record.sp === value,
			sorter: (a, b) => a.sp - b.sp,
			render: (sp) => (
				<div style={{ textAlign: "center" }}>
					{sp == 1 ? 1 : sp == 2 ? 2 : 3}
				</div>
			),
		},
		{
			title: t("tables.support_tickets_table.contact_info"),
			dataIndex: "contact_info",
			key: "contact_info",
			width: 160,
			filters: [
				...new Set(
					dataSource?.map(
						(res, index) => res?.contact_info
					)
				),
			].map((res, index) => {
				if (res !== null)
					return { text: res, value: res };
                else
                    return { text: t('general.unknown'), value: null };
			}),
			onFilter: (value, record) => record.contact_info === value,
			filteredValue: filteredInfo.contact_info || null,
			filterSearch: true,
            render: (contact_info) => <div style={{ textAlign: "center" }}>{contact_info ? contact_info : t('general.unknown')}</div>,
		},
		{
			title: t("tables.app_version"),
			dataIndex: "app_version",
			key: "app_version",
			width: 140,
			filteredValue: filteredInfo.app_version || null,
			filters: [
				...new Set(responseData?.map((res, index) => res.app_version)),
			].map((res, index) => {
				return { text: res, value: res };
			}),
			onFilter: (value, record) => record.app_version === value,
		},
		{
			title: t("tables.detail"),
			dataIndex: "detail",
			width: 300,
			key: "detail",
			filteredValue: filteredInfo.detail || null,
			...getColumnSearchProps("detail"),
		},
	];


    return (
        <>
            <SEO_HELMET title={t('tables.support_tickets_table.title')} />
            <div id="SupportTickets" style={{height: '100%', width: '100%'}}>
                <div>
                    <div>
                        <Table
                            footer={(currentPageData) => <div className='d-flex align-items-center justify-content-between'>
                                <div>{t('tables.total_data')}: <b>{currentPageData.length}</b> </div>
                                <Row>
                                    <Space direction='horizontal'>
                                        {user_info?.permissions?.resolved_support_tickets_page_read ?
                                            <Button
                                                href={`${process.env.REACT_APP_MEDIA_URL + routes.resolved_support_ticket.path}`}
                                                target="_blank"
                                                rel="noreferrer"                                                
                                                type="primary"
                                            >
                                                {t('tables.support_tickets_table.resolved_tickets')}
                                            </Button>
                                        :
                                            <Button
                                                href={`##`}
                                                style={{ background: '#e6f4ff', color: '#0958d9', borderColor: '#329bd7' }}
                                                type="primary"
                                                onClick={() => {
                                                    toast.error(t('toast.permission_error'), {
                                                        position: "top-right",
                                                        autoClose: false,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                    });
                                                }}
                                            >
                                                {t('tables.support_tickets_table.resolved_tickets')}
                                            </Button>
                                        }
                                        <Button onClick={() => setFilteredInfo({})}>{t('tables.clear_filters')}</Button>
                                    </Space>
                                </Row>
                            </div>
                            }
                            size='middle'
                            bordered={true}
                            columns={columns}
                            pagination={false}
                            dataSource={dataSource}
                            loading={responseDataLoading}
                            scroll={{
                                y: 'calc(100vh - 170px)',
                            }}
                            onChange={tablePropOnChange}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SupportTickets
