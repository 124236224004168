import React, { useCallback, useEffect, useState } from "react";
import { useMemo } from "react";
import Moment from "moment";
import "moment/locale/tr";
import "./GtwList.scss";
import "./../../../components/optim_table/OptimTable.scss";
import Modal from "react-bootstrap/Modal";

import { useDispatch, useSelector } from "react-redux";
import { memo } from "react";
import { GET_MainPageTable } from "../../../core/apis/Apis";

import { Button, Input, Table, Tag, Typography, theme } from "antd";
import { setError, setLoading, setSuccess } from "../../../core/stores/apiStatusControllerSlice";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { routes } from "../../../core/route/router";
import { SEO_HELMET } from "../../../core/utils/helmet";
import { setTitle } from "../../../core/stores/navbarTitleControllerSlice";
import { addRemoveFavAction, getFavListAction } from "../../../core/actions/auth/authActions";
import { useTranslation } from "react-i18next";

function GtwList() {
  /* -------------------------------------------------------------------------- */
  //TODO -                             SELECTORS                                */
  /* -------------------------------------------------------------------------- */
  // const isModalOpen = useSelector(state => state.modalController.isModalOpen)
  const apiStatusController = useSelector((state) => state.apiStatusController);
  const dispatch = useDispatch();

  /* -------------------------------------------------------------------------- */
  //TODO -                             MEDIAQUERY                                */
  /* -------------------------------------------------------------------------- */
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  // const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  /* -------------------------------------------------------------------------- */
  //TODO -                             STATES                                   */
  /* -------------------------------------------------------------------------- */
  // const [userGroupsPermissions, setUserGroupsPermissions] = useState();

  const {
    authReducers: { user_info },
  } = useSelector((state) => state);

  const {
    token: {
      colorLink,
      colorLinkHover,
      colorLinkActive
    }
  } = theme.useToken();

  const { t } = useTranslation();

  const gtwListFilters = localStorage.getItem("gtwListFilters")
    ? JSON.parse(localStorage.getItem("gtwListFilters"))
    : {};

  const [show, setShow] = useState(false);
  const [page, setPage] = useState(<></>);
  const [pageTitle, setPageTitle] = useState("");
  const [responseData, setResponseData] = useState();
  // const [searchText, setSearchText] = useState('');
  // const [searchedColumn, setSearchedColumn] = useState('');
  // const searchInput = useRef(null);
  const [versionData, setVersionData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [sensorData, setSensorData] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const [subStatusData, setSubStatusData] = useState([]);
  const [countryCodeData, setCountryCodeData] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState(gtwListFilters);
  const [tenantFilter, setTenantFilter] = useState([]);
  const [favInputValue, setFavInputValue] = useState(localStorage.getItem("isThereFavorite") === "true" ? true : false);

  /* -------------------------------------------------------------------------- */
  //TODO -                             PAGINATION                                */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  //TODO                                Functions                               */
  /* -------------------------------------------------------------------------- */
  const handleClose = useCallback(() => setShow(false), []);

  useEffect(() => {
    dispatch(setTitle(t('pages.gateway_list.title')));
    dispatch(getFavListAction());
    getData();
  }, [dispatch]);

  const gtwFavControl = (gtwID) => {
    if (user_info?.permissions?.gtw_detail_page_add_favorite) {
      dispatch(addRemoveFavAction(gtwID));
    } else {
      toast.error(t('toast.permission_error'), {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const getData = () => {
    GET_MainPageTable()
      .then((res) => {
        setResponseData(res.data);
        dispatch(setError(false));
        dispatch(setSuccess(true));
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setError(true));
        dispatch(setSuccess(false));
        dispatch(setLoading(false));
        console.log("err : ", err);
        if (err.response.data.status === 200) {
          toast.error(t('pages.gateway_list.password_update_required_toast'), {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(t('toast.error_text'), {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        // toast.error(`${err}`, {
        //     position: "top-right",
        //     autoClose: false,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        // })
      });
  };

  const tablePropOnChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    localStorage.setItem("gtwListFilters", JSON.stringify(filters));
  };

  const [gtwIdInputValue, setGtwIdInputValue] = useState(null);

  const [data, setData] = useState([]);

  const deneme = (value) => {
    var valueLength = 0;
    if (value.desired) {
      if (value.desired.rooms) {
        for (let index = 0; index < value.desired.rooms.length; index++) {
          const element = value.desired.rooms[index];
          if (element.nodes) {
            valueLength = valueLength + element.nodes.length;
          }
        }
      }
    }
    return valueLength;
  };

  const abc = useMemo(() => {
    var list = [];
    var versionList = [];
    var userList = [];
    var sensorList = [];
    var roomList = [];
    var subStatusList = [];
    var countryCodeList = [];
    const tenantList = [];
    if (responseData) {
      responseData?.map((res, index) => {
        if (res.shadow && res.gateway_info) {
          var shadow = JSON.parse(res.shadow.replaceAll("'", '"').replaceAll("True", true).replaceAll("False", false));
          var gateway_info = JSON.parse(
            res.gateway_info
              .replaceAll("Decimal('", "")
              .replaceAll("'),", ",")
              .replaceAll("')}", "}")
              .replaceAll("'", '"')
              .replaceAll("True", true)
              .replaceAll("False", false)
              .replaceAll("None", null)
          );

          var gwSetupCountryCode = null;
          var sensorLength = 0;
          var roomsLength = 0;
          var userCount = 0;
          var subStatus = null;
          var ESP_ver = "-";
          var gtwTag = "";
          let mhz;

          if (shadow.state && shadow.state.reported && shadow.state.reported.ESP_ver) {
            ESP_ver = shadow.state.reported.ESP_ver;
            versionList.push(ESP_ver);
          } else {
            ESP_ver = "-";
          }

          if (shadow.state && shadow.state.reported && shadow.state.reported.place_name) {
            gtwTag = shadow.state.reported.place_name;
          }

          if (
            shadow.state &&
            shadow.state.reported &&
            shadow.state.reported.paired_users &&
            shadow.state.reported.paired_users
          ) {
            userCount =
              (shadow.state.reported.paired_users.guardian_angel
                ? shadow.state.reported.paired_users.guardian_angel.length
                : 0) +
              (shadow.state.reported.paired_users.monitored_user
                ? shadow.state.reported.paired_users.monitored_user.length
                : 0);
            userList.push(userCount);
          } else {
            userCount = 0;
          }

          if (shadow.state && shadow.state.desired && shadow.state.desired.rooms) {
            roomsLength = shadow.state.desired.rooms.length;
            roomList.push(roomsLength);
          } else {
            roomsLength = 0;
          }

          if (shadow.state) {
            sensorLength = deneme(shadow.state);
            sensorList.push(sensorLength);
          } else {
            sensorLength = 0;
          }

          if (gateway_info && gateway_info.gwSetupCountryCode) {
            gwSetupCountryCode = gateway_info.gwSetupCountryCode;
            countryCodeList.push(gwSetupCountryCode);
          } else {
            gwSetupCountryCode = null;
          }
          if (shadow.state && shadow.state && shadow.state.reported && shadow.state.reported.subscription) {
            subStatus = shadow.state.reported.subscription.subscription_status;
            if (subStatus) subStatusList.push(subStatus);
          } else {
            subStatus = null;
          }

          shadow?.state?.reported?.frequency ?
            mhz = shadow?.state?.reported?.frequency
            : userCount > 0 && roomsLength > 0 && sensorLength > 0 ?
              mhz = 868
              : mhz = null;

          // if (!tenantList.some(tenant => tenant.text === res.tenant_name)) {
          //     tenantList.push({"text": res.tenant_name, "value": res.tenant_name});
          // }
          if (!tenantList.some((tenant) => tenant.text === res.tenant_id)) {
            tenantList.push({ text: res.tenant_id, value: res.tenant_id });
          }
          list.push({
            key: index,
            gtwID: res.device_serial.split("GTWY-")[1],
            gtwTag: gtwTag,
            installationStatus: userCount > 0 && roomsLength > 0 && sensorLength > 0 ? true : false,
            connectionStatus: res.conn_status == true ? true : false,
            // instRun: 'todo',
            version: ESP_ver,
            tags: "todo",
            // supportRequest: 'todo',
            location: gwSetupCountryCode,
            room: roomsLength,
            sensor: sensorLength,
            userCount: userCount,
            subStatus: subStatus,
            tenant_name: res.tenant_id,
            frequency: mhz,
            isFav:
              user_info?.user_settings?.gtw_fav_list?.find((f) => f == res.device_serial.split("GTWY-")[1]) != undefined
                ? true
                : false,
          });
        }
      });
    }
    setTenantFilter(tenantList);
    setData(list);
    setVersionData(Array.from(new Set(versionList)));
    setUserData(Array.from(new Set(userList)));
    setRoomData(Array.from(new Set(roomList)));
    setSensorData(Array.from(new Set(sensorList)));
    setSubStatusData(Array.from(new Set(subStatusList)));
    setCountryCodeData(Array.from(new Set(countryCodeList)));
    if (favInputValue === true) {
      return list.filter((res) => res.isFav === true);
    }

    return list;
  }, [responseData, user_info, favInputValue]);

  const columns = [
    {
      title: (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row" }}>
          <div style={{ width: "100px" }}>{t('tables.gtw_id')}: </div>
          <Input
            // ref={searchInput}
            placeholder={t('tables.search')}
            value={gtwIdInputValue}
            // value={selectedKeys[0]}
            onChange={(e) => {
              setGtwIdInputValue(e.target.value.toUpperCase());
            }}
            // onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          />
        </div>
      ),
      dataIndex: "gtwID",
      key: "gtwID",
      width: 160,
      fixed: "left",
      filteredValue: filteredInfo.gtwID || null,
      sorter: (a, b) => a.gtwID?.localeCompare(b.gtwID),
      render: (gtwID) => {
        if (user_info?.permissions?.gtw_detail_page_read) {
          return (
            <div style={{ textAlign: "center" }}>
              <Typography.Link href={`${routes.gateway_detail.path}/GTWY-${gtwID}`} target="_blank" rel="noreferrer">
                {gtwID}
              </Typography.Link>
            </div>
          );
        } else {
          return (
            <div style={{ textAlign: "center" }}>
              <Typography.Link
                href={"##"}
                onClick={() => {
                  toast.error(t('toast.permission_error'), {
                    position: "top-right",
                    // autoClose: false,
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }}
              >
                {gtwID}
              </Typography.Link>
            </div>
          );
        }
      },
      // ...getColumnSearchPropsForGtwID('gtwID'),
    },
    {
      title: t('tables.gtw_list_table.gtw_tag'),
      dataIndex: "gtwTag",
      key: "gtwTag",
      filteredValue: filteredInfo.gtwTag || null,
      sorter: (a, b) => a.gtwTag?.localeCompare(b.gtwTag),
      width: 120,
    },
    {
      title: t('tables.gtw_list_table.installation'),
      dataIndex: "installationStatus",
      key: "installationStatus",
      width: 100,
      sortDirections: ["descend", "ascend"],
      filters: [
        {
          text: (
            <Tag style={{ margin: "0" }} color="success" bordered>
              {t('tables.gtw_list_table.filters.installed')}
            </Tag>
          ),
          value: true,
        },
        {
          text: (
            <Tag style={{ margin: "0" }} color="red" bordered>
              {t('tables.gtw_list_table.filters.not_installed')}
            </Tag>
          ),
          value: false,
        },
      ],
      filteredValue: filteredInfo.installationStatus || null,
      onFilter: (value, record) => record.installationStatus === value,
      // sorter: (a, b) => a.connectionStatus - b.connectionStatus,
      render: (installationStatus) => (
        <div style={{ textAlign: "center" }}>
          {installationStatus ? (
            <Tag style={{ margin: "0" }} color="success">
              {t('tables.gtw_list_table.filters.installed')}
            </Tag>
          ) : (
            <Tag style={{ margin: "0" }} color="red">
              {t('tables.gtw_list_table.filters.not_installed')}
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: t('tables.gtw_list_table.connection'),
      dataIndex: "connectionStatus",
      key: "connectionStatus",
      width: 100,
      sortDirections: ["descend", "ascend"],
      filters: [
        {
          text: (
            <Tag style={{ margin: "0" }} color="success">
              {t('tables.gtw_list_table.filters.online')}
            </Tag>
          ),
          value: true,
        },
        {
          text: (
            <Tag style={{ margin: "0" }} color="red">
              {t('tables.gtw_list_table.filters.offline')}
            </Tag>
          ),
          value: false,
        },
      ],
      filteredValue: filteredInfo.connectionStatus || null,
      onFilter: (value, record) => record.connectionStatus === value,
      // sorter: (a, b) => a.connectionStatus - b.connectionStatus,
      render: (connectionStatus) => (
        <div style={{ textAlign: "center" }}>
          {connectionStatus == true ? (
            <Tag style={{ margin: "0" }} color="success">
              {t('tables.gtw_list_table.filters.online')}
            </Tag>
          ) : (
            <Tag style={{ margin: "0" }} color="red">
              {t('tables.gtw_list_table.filters.offline')}
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: t('tables.version'),
      dataIndex: "version",
      key: "version",
      width: 90,
      filteredValue: filteredInfo.version || null,
      filters:
        versionData.length > 0 &&
        versionData.map((res) => {
          return {
            text: res,
            value: res,
          };
        }),
      onFilter: (value, record) => record.version === value,
      sorter: (a, b) => {
        const aVersion = a.version === "-" ? "0" : a.version;
        const bVersion = b.version === "-" ? "0" : b.version;

        return parseInt(aVersion) - parseInt(bVersion);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: t('tables.location'),
      dataIndex: "location",
      key: "location",
      width: 90,
      filteredValue: filteredInfo.location || null,
      filters:
        countryCodeData.length > 0 &&
        countryCodeData.map((res) => {
          return {
            text: res,
            value: res,
          };
        }),
      onFilter: (value, record) => record.location == value,
    },
    {
      title: t('tables.gtw_list_table.room'),
      dataIndex: "room",
      key: "room",
      width: 100,
      filteredValue: filteredInfo.room || null,
      filters:
        roomData.length > 0 &&
        roomData.map((res) => {
          return {
            text: res,
            value: res,
          };
        }),
      onFilter: (value, record) => record.room === value,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.room - b.room,
      // ...getColumnSearchProps('room'),
    },
    {
      title: t('tables.gtw_list_table.sensor'),
      dataIndex: "sensor",
      key: "sensor",
      width: 100,
      filteredValue: filteredInfo.sensor || null,
      filters:
        sensorData.length > 0 &&
        sensorData.map((res) => {
          return {
            text: res,
            value: res,
          };
        }),
      onFilter: (value, record) => record.sensor === value,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.sensor - b.sensor,
      // ...getColumnSearchProps('sensor'),
    },
    {
      title: t('tables.gtw_list_table.user_count'),
      dataIndex: "userCount",
      key: "userCount",
      width: 90,
      filteredValue: filteredInfo.userCount || null,
      filters:
        userData.length > 0 &&
        userData.map((res) => {
          return {
            text: res,
            value: res,
          };
        }),
      onFilter: (value, record) => record.userCount === value,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.userCount - b.userCount,
      // ...getColumnSearchProps('userCount'),
    },
    {
      title: t('tables.gtw_list_table.frequency'),
      dataIndex: "frequency",
      key: "frequency",
      width: 90,
      filters: [...new Set(abc.filter((res) => res.frequency !== null)
        .map((res) => res.frequency))]
        .map((res) => {
          return {
            text: res + " MHz",
            value: res,
          };
        }
      ),
      filterSearch: true,
      filteredValue: filteredInfo.frequency || null,
      onFilter: (value, record) => record.frequency === value,
      render: (text, record) => {
        return text && (
          <div style={{ textAlign: "center" }}>{text + ' MHz'}</div>
        );
      },
    },
    {
      title: t('tables.gtw_list_table.sub_status'),
      dataIndex: "subStatus",
      key: "subStatus",
      width: 100,
      filteredValue: filteredInfo.subStatus || null,
      filters: [...new Set(subStatusData.filter((res) => res !== null && res !== undefined))]
        .map((res) => {
          return {
            text: t(`tables.gtw_list_table.filters.${res}`),
            value: res,
          };
      }),
      onFilter: (value, record) => record.subStatus === value,
      // sorter: (a, b) => a.subStatus - b.subStatus,
      // sortDirections: ['descend', 'ascend'],
      // render: (subStatus) => <div style={{ textAlign: 'center', fontSize: '7px' }} >{subStatus == 'active' ? <Badge size='small' status="success" text=<span style={{ fontSize: '11px', fontWeight: '600' }}>Subscribed</span> /> : subStatus == 'cancelled' ? <Badge size='small' status="warning" text=<span style={{ fontSize: '11px', fontWeight: '600' }}>Canceled</span> /> : subStatus == 'grace' ? <Badge size='small' status="processing" text=<span style={{ fontSize: '11px', fontWeight: '600' }}>Grace</span> /> : <Badge size='small' status="error" text=<span style={{ fontSize: '11px', fontWeight: '600' }}>Non-subscribed</span> />}</div>,
      render: (subStatus) => (subStatus !== null && subStatus !== undefined) && <div style={{ textAlign: "start" }}>{t(`tables.gtw_list_table.filters.${subStatus}`)}</div>,
    },
    {
      title: (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row" }}>
          {/* <div style={{ width: '70px' }}>Fav :</div> */}
          <i
            className={favInputValue == true ? `fa-solid fa-star` : `fa-regular fa-star`}
            style={{ color: favInputValue == true ? "red" : "#aaaaaa", cursor: "pointer", fontSize: "20px" }}
            onClick={() => {
              if (favInputValue === false && user_info?.user_settings?.gtw_fav_list?.length === 0) {
                toast.info(t('pages.gateway_list.empty_fav_list_info_toast'), {
                  position: "top-right",
                  autoClose: false,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setFavInputValue(false);
                localStorage.setItem("isThereFavorite", false);
              } else {
                setFavInputValue(!favInputValue);
                localStorage.getItem("isThereFavorite") === "true"
                  ? localStorage.setItem("isThereFavorite", false)
                  : localStorage.setItem("isThereFavorite", true);
              }
            }}
          ></i>
        </div>
      ),
      dataIndex: "isFav",
      key: "isFav",
      width: 26,
      filteredValue: filteredInfo.isFav || null,
      onFilter: (value, record) => record.isFav === value,
      render: (isFav, currentGtw) => (
        <div style={{ textAlign: "center" }}>
          {isFav == true ? (
            <i
              className="fa-solid fa-star"
              onClick={() => {
                gtwFavControl("GTWY-" + currentGtw.gtwID);
                isFav = !isFav;
              }}
              style={{ color: "red" }}
            ></i>
          ) : (
            <i
              onClick={() => gtwFavControl("GTWY-" + currentGtw.gtwID)}
              className="fa-regular fa-star"
              style={{ color: "#aaaaaa" }}
            ></i>
          )}
        </div>
      ),
    },
  ];

  if (user_info?.is_superuser) {
    columns.splice(columns.length - 1, 0, {
      title: t('tables.tenant_id'),
      dataIndex: "tenant_name",
      key: "tenantName",
      width: 100,
      // sorter: (a, b) => a.tenant_name.localeCompare(b.tenant_name),
      // filteredValue: filteredInfo.tenant_name || null,
      // filterMode: "tree",
      filters: tenantFilter,
      filterSearch: true,
      filteredValue: filteredInfo.tenantName || null,
      onFilter: (value, record) => record.tenant_name === value,
    });
  }

  return (
    <>
      <SEO_HELMET title={t('pages.gateway_list.title')} />
      <CustomModal show={show} handleClose={handleClose} pageTitle={pageTitle} page={page} />
      <div id="GtwList">
        <div className="GtwList-cover">
          <div className="GtwList-bottom_more_info">
            <Table
              footer={(currentPageData) => (
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    {t('tables.total_data')}: <b>{currentPageData.length}</b>{" "}
                  </div>
                  <Button
                    onClick={() => {
                      setFilteredInfo({});
                      localStorage.removeItem("gtwListFilters");
                      setGtwIdInputValue(null);
                    }}
                  >
                    {t('tables.clear_filters')}
                  </Button>
                </div>
              )}
              size="middle"
              bordered={true}
              columns={columns}
              pagination={false}
              dataSource={gtwIdInputValue ? abc.filter((res) => res.gtwID?.includes(gtwIdInputValue)) : abc}
              loading={responseData ? false : true || apiStatusController.isLoading == true ? true : false}
              scroll={{
                y: "calc(100vh - 190px)",
              }}
              onChange={tablePropOnChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default GtwList;

const CustomModal = memo(({ show, handleClose, pageTitle, page }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      style={useMemo(() => {
        return { width: "calc(100vw - 65px)", transform: "translateX(37.5px) translateY(20px)" };
      }, [])}
    >
      <Modal.Header style={{ width: "100%", margin: "0" }} closeButton>
        <Modal.Title>{pageTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={useMemo(() => {
          return { width: "100%", margin: "0" };
        }, [])}
      >
        {page}
      </Modal.Body>
    </Modal>
  );
});
